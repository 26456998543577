import React, { useEffect, useState } from 'react'
import db from '../../db'
import xbut from '../../images/create frame/x.png'
import editbut from '../../images/create frame/editbut.png'
import { Link } from "react-router-dom"
export default function PS4details({ x, counter, edit, remove, user }) {

    const [validRemove, setValidRemove] = useState(false)
    useEffect(() => (async () => setValidRemove(
        (await db.Cdstores.findcdid(x.id)) == 0
    ))(), [x])
    return (
        <div>
            {user && user.role == "Admin" ?
                < >
                    <img class="xbutcd" src={xbut} ></img>
                    <button class="xbuttcd" variant="light" onClick={() => remove(x.id)} disabled={!validRemove} >X</button>
                    <img class="editbutcd" src={editbut} ></img>
                    <button class="editbuttcd" variant="light" onClick={() => edit(x.id)}>Edit</button>
                </> : null}
            <Link to={`/games/${x.id}`}>
                <div class="cardd" style={{ animation: `${counter}s ease-out  1 fadeIn` }}>
                    <div class="b-game-card">
                        <div class="b-game-card__cover" style={{ background: `url("${x.smallimage}") no-repeat center center/cover` }}></div>
                    </div></div>
            </Link>
        </div>
    )
}