import firebase from '../../firebase'
import { useHistory } from 'react-router-dom';

function Logout() {
    const history = useHistory()
    const logout = async () => {
        await firebase.auth().signOut()
        history.push("/")
    }
    logout()
    return null
}

export default Logout;