import React, { useState, useEffect } from 'react'
import firebase from './firebase'
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"
import Login from './starter/public/Login'
import Register from './starter/public/Register'
import Home from './starter/public/Home'
import Logout from './starter/public/Logout'
import UserContext from './UserContext'
import db from './db';
import "./css/loader.css";
import Stores from './starter/All files/Stores'
import Cds from './starter/All files/Cds'
import Games from './starter/All files/Games'
import Ps4 from './starter/All files/Ps4'
import Ps5 from './starter/All files/Ps5'
import StoreDetails from './starter/All files/StoreDetails'
import { HashRouter } from 'react-router-dom'

export default function App() {
    const [user, setUser] = useState(null)
    useEffect(() => {
      const findAndSetUser = async user => {
        let dbUser = null
        if (user) {
          dbUser = await db.Users.findOne(user.uid)
          if (!dbUser) {
            // create new Users document but with set id
            await db.Users.update({ id: user.uid, name: "", role: "Customer" })
            dbUser = await db.Users.findOne(user.uid)
          }
        }
        setUser(dbUser)
      }
      return firebase.auth().onAuthStateChanged(findAndSetUser)
    }, [])

    const isPublic = () => user === null
    const isLoggedIn = () => user !== null
    const isAdmin = () => user?.role === "Admin"
    const isCustomer = () => user?.role === "Customer"

    return (
        <UserContext.Provider value={{ user }}>
            <HashRouter>
                <div >
           
                    <div class="container-fluid mainContainer">
                        <nav class="navbar navbar-expand-md navbar-dark">
                            <a class="navbar-brand" ><Nav.Link as={Link} style={{color:"white"}} to="/">HOME</Nav.Link> </a>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="collapsibleNavbar">
                                <ul class="navbar-nav">
                                    {
                                        isCustomer() &&
                                        <>
                                            <li class="nav-item">   <Nav.Link as={Link} to="/stores">Stores</Nav.Link>  </li>
                                            <NavDropdown as={Link} to="/products" title="Games" id="basic-nav-dropdown">
                                                <NavDropdown.Item as={Link} to="/cds">All Games</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/ps4">PS4</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/ps5">PS5</NavDropdown.Item>
                                            </NavDropdown>
                                        </>
                                    }

                                    {
                                        isAdmin() &&
                                        <>
                                            <li class="nav-item">   <Nav.Link as={Link} to="/stores">Stores</Nav.Link>  </li>
                                            <NavDropdown as={Link} to="/products" title="Games" id="basic-nav-dropdown">
                                                <NavDropdown.Item as={Link} to="/cds">All Games</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/ps4">PS4</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/ps5">PS5</NavDropdown.Item>
                                            </NavDropdown>
                                        </>
                                    }
                                    {
                                        isPublic()
                                        &&
                                        <>
                                            <li class="nav-item">   <Nav.Link as={Link} to="/stores">Stores</Nav.Link>  </li>
                                            <NavDropdown as={Link} to="/products" title="Games" id="basic-nav-dropdown">
                                                <NavDropdown.Item as={Link} to="/cds">All Games</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/ps4">PS4</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/ps5">PS5</NavDropdown.Item>
                                            </NavDropdown>
                                            {/* <li class="nav-item"> <a class="nav-link" href="/cds">Games</a> </li> */}
                                            <li class="nav-item last">   <Nav.Link as={Link} to="/login">Login</Nav.Link>  </li>
                                            <li class="nav-item last" style={{ marginRight: '150px' }}>   <Nav.Link as={Link} to="/register">Register</Nav.Link>  </li>

                                        </>
                                    }

                                    {
                                        isLoggedIn()
                                        &&
                                        <li class="nav-item last">   <Nav.Link as={Link} to="/logout">Logout</Nav.Link>  </li>
                                    }

                                </ul>
                            </div>
                        </nav>



                    </div>


                    {
                        isPublic()
                        &&
                        <Switch>
                            <Route path="/games/:mid" set={setUser}>
                                <Games />
                            </Route>
                            <Route path="/stores" set={setUser}>
                                <Stores />
                            </Route>
                            <Route path="/register">
                                <Register />
                            </Route>
                            <Route path="/login">
                                <Login  />
                            </Route>
                            <Route path="/storedetails/:id">
                                <StoreDetails />
                            </Route>
                            <Route path="/cds">
                                <Cds />
                            </Route>
                       
                            <Route path="/ps4">
                                <Ps4 />
                            </Route>
                            <Route path="/ps5">
                                <Ps5 />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    }
                    {
                        isLoggedIn()
                        &&
                        <Switch>
                            <Route path="/logout">
                                <Logout />
                            </Route>
                        </Switch>
                    }
                    {
                        isCustomer()
                        &&
                        <Switch>
                            <Route path="/games/:mid" set={setUser}>
                                <Games />
                            </Route>
                            <Route path="/stores" set={setUser}>
                                <Stores />
                            </Route>
                            <Route path="/register">
                                <Login type="Register" />
                            </Route>
                            <Route path="/login">
                                <Login type="Login" />
                            </Route>
                            <Route path="/storedetails/:id">
                                <StoreDetails />
                            </Route>
                            <Route path="/cds">
                                <Cds />
                            </Route>
                            <Route path="/ps4">
                                <Ps4 />
                            </Route>
                            <Route path="/ps5">
                                <Ps5 />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    }
                    {
                        isAdmin()
                        &&
                        <Switch>
                            <Route path="/games/:mid" set={setUser}>
                                <Games />
                            </Route>
                            <Route path="/stores" set={setUser}>
                                <Stores />
                            </Route>
                            <Route path="/register">
                                <Login type="Register" />
                            </Route>
                            <Route path="/login">
                                <Login type="Login" />
                            </Route>
                            <Route path="/storedetails/:id">
                                <StoreDetails />
                            </Route>
                            <Route path="/cds">
                                <Cds />
                            </Route>
                            <Route path="/ps4">
                                <Ps4 />
                            </Route>
                            <Route path="/ps5">
                                <Ps5 />
                            </Route>
                            <Route path="/stores">
                                <Stores />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    }


                    <footer class="footer" style={{ position: "relative", backgroundColor: "black", marginTop: "-10px" , marginLeft:"-7%"}}>
                        <ul style={{ color: "black" }}>
                        <li >   <Link as={Link} to="/">Home</Link>  </li>
                        <li >   <Nav.Link as={Link} to="/cds">Playstation Games</Nav.Link>  </li>

                            <li><span style={{color:"gray"}}>Instagram:</span><a href="http://instagram.com/_u/babbide.boo"> @babbidie.boo</a></li>
                            <li><span style={{color:"gray"}}>Email:</span><a href="mailto:babbidi.boo@outlook.com"> babbidi.boo@outlook.com</a></li>
                      
                        </ul>
                    </footer>
                </div>
            </HashRouter>
        </UserContext.Provider >
    )
}