import React, { useEffect, useState } from 'react'
import db from '../../db'
import { useParams } from "react-router-dom";
import sdetail from '../../images/stdetails.png'
import purple from '../../images/purple.png'
import loader from '../../images/loader.png'
import "../../css/storedetails.css";
import actionimg from '../../images/gener gif/actionimg.gif'
import ProgressiveImage from "react-progressive-graceful-image";
export default function StoreDetails() {
  const { id: stringId } = useParams();
  const id = stringId
  const [store, setStores] = useState(null)
  useEffect(() => db.Stores.listenOne(setStores, id), [id])
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    store
    &&
    <>{loading ? (
      <div className="loader-container">
        <div className='centerstore'>
          <ProgressiveImage src={loader} >{(src, loading) => (<img className={`spinner${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
        </div>
        <p className='lotext' > Loading...</p>
      </div>
    ) :
      <div className='allstore'>
        <br></br><br></br><br></br><br></br><br></br><br></br>
        <div className='centerstore'>
          <ProgressiveImage src={store.image} >{(src, loading) => (<img className={`stimage${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          <ProgressiveImage src={sdetail} >{(src, loading) => (<img className={`stdetail${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
        </div>
        <div className='alltext'>
          <div className='centerstore'><p class="storetext"> Name:</p></div>
          <div className='centerstore'>
            <p class="purpletext"> {store.name}</p>
            <ProgressiveImage src={purple} >{(src, loading) => (<img className={`purple${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'><p class="storetext"> Location:</p></div>
          <div className='centerstore'>
            <p class="purpletext"> {store.location}</p>
            <ProgressiveImage src={purple} >{(src, loading) => (<img className={`purple${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'><p class="storetext"> Phone Number:</p></div>
          <div className='centerstore'>
            <a class="purpletext" href={`tel:${store.phonenum}`}>{store.phonenum}</a>
            <ProgressiveImage src={purple} >{(src, loading) => (<img className={`purple${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div></div>
        <div className='centerstore'>
          <ProgressiveImage src={actionimg} >{(src, loading) => (<img className={`actionimg${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
        </div>
      </div>

    }
    </>
  )
}