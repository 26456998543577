import React, { useEffect, useState } from 'react'
import db from '../../db'
import "../../css/cds.css";
import "../../css/stylee.css";
import "../../css/slider.css";
import "../../css/ps4and5.css";
import "../../css/trigger.css";
import "../../css/footer.css";
import "../../css/scrolltrigger.css";
import $ from 'jquery';
import image from '../../images/image.png'
import image1 from '../../images/image2.png'
import image3 from '../../images/image3.png'
import ps4image from '../../images/ps4.png'
import ps5image from '../../images/ps5.png'
import wavy from '../../images/wavy.png'
import spider2 from '../../images/spider2.png'
import image22 from '../../images/image22.png'
import image33 from '../../images/image33.png'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap";
import {Link} from "react-router-dom"

export default function Home() {
  const [cds, SetCds] = useState([])
  useEffect(() => db.Cds.listenAll(SetCds), [])
  let d = new Date().getFullYear()
  let counter = 0;
  let mostrescent = []
  for (let i = 0; i < cds.length; i++) {
    if (( cds[i].date.getFullYear())>=2022){
      if (counter < 4) {
        mostrescent.push(cds[i])
        counter++
      }
    }
  }

  $(".element").each(function () {
    $(this).mouseover(function () {
      $(this).addClass("active");
      $(".stage").children(".element").not(".active").addClass("inactive");
    });
    $(this).mouseleave(function () {
      $(this).removeClass("active");
      $(".stage").children(".element").not(".active").removeClass("inactive");
    });
  });


  $(function ($) {
    var doAnimations = function () {
      var offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
      if ($animatables.length == 0) {
        $(window).off('scroll', doAnimations);
      }
      $animatables.each(function (i) {
        var $animatable = $(this);
        if (($animatable.offset().top + $animatable.height() - 20) < offset) {
          $animatable.removeClass('animatable').addClass('animated');
        }
      })
    };
    $(window).on('scroll', doAnimations);
    $(window).trigger('scroll');
  });


  //-------------------------------------------------
  function animateFrom(elem, direction) {
    direction = direction || 1;
    var x = 0,
      y = 0;
    if (elem.classList.contains("up")) {
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: 0, y: 20, autoAlpha: 0 }, {
        duration: 1.5,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      });
    }
    else if (elem.classList.contains("up1")) {
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        delay: 0.2

      });
    }
    else if (elem.classList.contains("up2")) {
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        delay: 0.4
      });
    }

    else if (elem.classList.contains("up3")) {
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        delay: 0.6
      });
    }
    else if (elem.classList.contains("up4")) {
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        delay: 0.8
      });
    }
    else if (elem.classList.contains("upp")) {
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: 0, y: 30, autoAlpha: 0 }, {
        duration: 2,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      });
    }
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  document.addEventListener("DOMContentLoaded", function () {
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view
      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () { animateFrom(elem) },
        onEnterBack: function () { animateFrom(elem, -1) },
        onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
      });
    });
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

let count=0
  return (
    <>

      <div >
         <div className='allsha'>
        <div className='containernav'>
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carouselslider" >
              <div class="carousel-item " >
                <img class="imageslider" src={image} alt="First slide"></img>
                <img id="spider" src={spider2} alt="First slide"></img>
                <div class="carousel-caption ">
                  <h5 class="ttext"> Check out the latest PlayStation games</h5>
                  <p class="ptext">See the games available in most of Qatar's stores</p>
                </div>
              </div>
              <div class="carousel-item active ">
                <img class="imageslider" src={image1} alt="Second slide"></img>
                <img id="image2" src={image22} alt="First slide"></img>
                <div class="carousel-caption ">
                  <h5 class="ttext2">Get to know the Playstation store in Qatar</h5>
                  <p class="ptext2">You can see the details of the store and the games available in it</p>
                </div>
              </div>
              <div class="carousel-item  ">
                <img class="imageslider" src={image3} alt="Third slide"></img>
                <img id="image3" src={image33} alt="First slide"></img>
                <div class="carousel-caption  ">
                  <h5 class="ttext3">Compare prices</h5>
                  <p class="ptext3">Find out the prices of the games in each store with the details of each game</p>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>

        </div>
        {/* -------------------------------------------------- */}
       
        {/* <div class="try"></div> */}

        <div class="up gs_reveal">
          <div className='body'>
            <span>M</span><span>O</span><span>S</span><span>T</span><span>&nbsp;
            </span><span>R</span><span>E</span><span>C</span><span>E</span><span>N</span><span>T</span><span>&nbsp;</span>
            <span>G</span><span>A</span><span>M</span><span>E</span><span>S</span>
          </div>  </div>
          <br></br>

        <div class="l-container">
          {mostrescent.map((x, i) =>
          <><div style={{ display: "none" }}>{count = count + 0.5}</div>
            <Link to={`/games/${x.id}`} data-aos="zoom-in-up" style={{ transitionDuration: `${count}s`}}>
              <div class={`"up${i} gs_reveal"`}>
                <div class="cardd" >
                  <div class="b-game-card">
                    <div class="b-game-card__cover" style={{ background: `url("${x.smallimage}")no-repeat center center/cover` }}></div>
                  </div></div></div>  </Link></>)}
        </div>
      </div>
        {/* -------------------------------------------------- */}
        <div class="upp gs_reveal">
          <div className='centerstore'>  
          <h2 class="ps54font" >Playstation 4 & 5 Games</h2>
          </div>  </div>
        <div class="gradient-border" id="box">
          <img class="crash" src={wavy} ></img>
          <section id="hero12" className="showcase2" >
            <div class="pssection">
              <div class="panels__container">
               <Link to="/Ps5" class="panel" >
                  <div class="" >
                    <div id="image-box" class="panel__contentt" >
                      <img style={{ height: '28vw', marginTop: '-5vw' }} src={ps5image}></img>
                      <h3 class="panel__titlee" style={{ marginTop: '-9vw' }}>PS5</h3>
                    </div>
                  </div>
                </Link>               
                  <Link to="/Ps4" class="panel">
                    <div class="">
                      <div id="image-box" class="panel__contentt" >
                        <img style={{ height: '20vw' }} src={ps4image}></img>
                        <h3 class="panel__titlee" style={{ marginTop: '-6vw' }}>PS4</h3>
                      </div></div>
                  </Link>
                </div> 
            </div>
            <div className="showcase-top" >
            </div>
          </section>
        </div>
        <br></br><br></br><br></br>
      </div>
    </>
  )
}