import React, { useEffect, useState } from 'react'
import db from '../../db'
import Button from 'react-bootstrap/Button';
import stcircle from '../../images/stcircle.png'
import xbut from '../../images/create frame/x.png'
import editbut from '../../images/create frame/editbut.png'
import { Link } from "react-router-dom"
import ProgressiveImage from "react-progressive-graceful-image";

export default function Store({ x, edit, remove, user }) {
  const [validRemove, setValidRemove] = useState(false)
  useEffect(() => (async () => setValidRemove(
    (await db.Cdstores.findstoreid(x.id)) == 0
  ))(), [x])

  return (
    <>
      <div >
        <div className='centerstore'>
          <ProgressiveImage src={stcircle} >{(src, loading) => (<img className={`stcircle${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
        </div>
        <figure class="figure__card"  >
          {user && user.role == "Admin" ? <div class="dbut">
            <img class="xbut" src={xbut} ></img>
            <button class="xbutt" variant="light" onClick={() => remove(x.id)} disabled={!validRemove}  >X</button>
            <img class="editbut" src={editbut} ></img>
            <button class="editbutt" variant="light" onClick={() => edit(x.id)}>Edit</button>
          </div> : null}
          <img class="stci" src={x.image} ></img>
          <Link to={`/storedetails/${x.id}`}>
            <figcaption class="figure__card--caption">
              <div class="figure__card--title">
                <h3 class="sttext" style={{ textAlign: "center" }}>{x.name}</h3>
              </div>
            </figcaption>
          </Link>
        </figure>

      </div>











    </>


  )
}