import React, { useEffect, useState, useContext } from 'react'
import db from '../../db'
import Gamedetails from './Gamedetails'
import SuggestedGames from './SuggestedGames'
import $ from 'jquery';
import UserContext from '../../UserContext'
import "../../css/gamesetails.css";
import "../../css/storescards.css";
import "../../css/relatedgames.css";
import "../../css/aosi.css";
import loader from '../../images/loader.png'
import witch from '../../images/witch.gif'
import spiderdetails from '../../images/spiderdetails.png'
import detailsoverlay from '../../images/detailsoverlay.png'
import createFrame from '../../images/create frame/createFrame.png'
import createbut from '../../images/create frame/createbut.png'
import updatebut from '../../images/create frame/updatebut.png'
import ProgressiveImage from "react-progressive-graceful-image";
import { useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom"
export default function Games() {
  const { mid: stringId } = useParams();
  const mid = stringId
  const { user } = useContext(UserContext)
  const [cds, setCds] = useState([])
  const [stores, setstores] = useState([])
  const [id, setId] = useState(0)
  const [price, setPrice] = useState("")
  const [storeid, setStoreId] = useState("")
  const [cdid, setCdId] = useState("")
  const [allgames, setallGames] = useState([])

  useEffect(() => db.Cds.listenAll(setallGames), [])
  useEffect(() => db.Cdstores.listenAll(setCds), [])
  useEffect(() => db.Stores.listenAll(setstores), [])

  var $animation_elements = $('.animation-element');
  var $window = $(window);
  function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);
    $.each($animation_elements, function () {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = (element_top_position + element_height);
      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
        $element.addClass('in-view');
      } else {
        $element.removeClass('in-view');
      }
    });
  }

  $window.on('scroll resize', check_if_in_view);
  $window.trigger('scroll');
  let ccid = ""
  allgames.map((x, i, z) => (x.id == mid) ? ccid = x.id : null)
  const create = async () => {
    await db.Cdstores.create({ price, storeid, cdid: ccid })
    setId(0)
    setPrice("")
    setStoreId("")
    setCdId("")

  }

  const remove = async id => await db.Cdstores.remove(id)
  const edit = async id => {
    const x = await db.Cdstores.findOne(id)
    console.log(x.storeid)
    setId(x.id)
    setPrice(x.price)
    setStoreId(x.storeid)
    setCdId(x.cdid)
  }


  const update = async () => {
    await db.Cdstores.update({ id, price, storeid, cdid: ccid })
    setId(0)
    setPrice("")
    setStoreId("")
    setCdId("")
  }

  const [validCreate, setValidCreate] = useState(false)
  useEffect(() => setValidCreate(
    price > 0 &&
    storeid != null
  ), [price, storeid, , cdid])

  const [validUpdate, setValidUpdate] = useState(false)
  useEffect(() => (async () => setValidUpdate(
    price > 0 &&
    storeid > 0 &&
    cdid != null &&
    await db.Cdstores.findOne(id) !== undefined
  ))(), [id, price, storeid, cdid])


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  let counter = 0
  let Suggestedarr = []
  let generstring = ""
  allgames.map((x) => x.id == mid ? generstring = x.gener : null)
  allgames.map((x) => {
    if (counter < 4) {
      if ((x.gener.split(",")[0] == generstring.split(",")[0]) || (x.gener.split(", ")[1] == generstring.split(",")[0]) || (x.gener.split(", ")[2] == generstring.split(",")[0])) {
        if (x.id != mid) {
          Suggestedarr.push(x)
          counter++
        }
      }
    }

  })

  let counter2 = 0
  let Suggestedarr2 = []
  allgames.map((x) => {

    if (counter2 < 4) {
      if ((x.gener.split(",")[0] == "Action")) {
        console.log(x)
        Suggestedarr2.push(x)
        counter2++
      }
    }

  })
  let arr = []
  cds.map((x) => {
    if (x.cdid == mid) {
      arr.push(x)
    }
  })
  let storesarr = []
  let obj = {}
  stores.map((x, i) => {
    cds.map((j) => {
      if (j.cdid == mid) {
        if (j.storeid == x.id) {
          storesarr.push(x)
          obj[x.id] = j.price
        }
      }
    })
  })

  $(function ($) {
    var doAnimations = function () {
      var offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
      if ($animatables.length == 0) {
        $(window).off('scroll', doAnimations);
      }
      $animatables.each(function (i) {
        var $animatable = $(this);
        if (($animatable.offset().top + $animatable.height() - 20) < offset) {
          $animatable.removeClass('animatable').addClass('animated');
        }
      });
    };
    $(window).on('scroll', doAnimations);
    $(window).trigger('scroll');
  });

  let largeimagestring = ""
  allgames.map((x, i) => x.id == mid ? largeimagestring = x.largeimage : null)
  //---------------------------------------------
  let count = 0
  let limit = 0
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className='centerstore'>
            <ProgressiveImage src={loader} >{(src, loading) => (<img className={`spinner${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <p className='lotext' > Loading...</p>
        </div>
      ) : <>
        <header class="showcasegames" style={{
          background: ` url("${largeimagestring}")
            no-repeat center center/cover` , backgroundPosition: "top"
        }}>
          <img className='showcasegames-content' src={spiderdetails}></img>
        </header>

        {
          cds.map((x, i) => (x.cdid == mid) ? <>
            <div style={{ display: "none" }}>{limit++}</div>
            <Gamedetails key={x.id} x={x} edit={edit} user={user} remove={remove} mid={mid} arr={arr} storesarr={storesarr} i={i} obj={obj} limit={limit} />
          </> : null
          )
        }
        <div data-aos="fade-up"
          data-aos-anchor-placement="center-center">
        </div>
        <div data-aos="fade-up"
          data-aos-anchor-placement="center-center">
        </div>
        {((user && user.role) == "Admin") ?
          <>  <div className='cdspace'>
            <div className='allframecd'>
              <img class="nameframe" src={createFrame} ></img>
              <input class="nameframetext" type="number" onChange={event => setPrice(1 * event.target.value)} placeholder="Price" value={price} />
              <img class="phoneframe" src={createFrame} ></img>
              <div class="phoneframetext">  <Form.Control as="select" size="sm" placeholder="Store" value={storeid} onChange={event => setStoreId(event.target.value)}>
                <option key={0} value={0} >Select Role</option>
                {
                  stores.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)
                }
              </Form.Control></div>
              {allgames.map((x, i, z) => (x.id == mid) ? <>
                <img class="locframe" src={createFrame} ></img>
                <input class="locframetext" type="number" onChange={event => setCdId(event.target.value)} placeholder={x.name} value={x.id} disabled />
              </> : null)}
              <div className='crupbut3'>
                <img class="createbut" src={createbut} ></img>
                <button className='createbuttext' variant="light" onClick={create} disabled={!validCreate}>Create</button>
                <img class="updatebut" src={updatebut} ></img>
                <button className='updatebuttext' variant="light" onClick={update} disabled={!validUpdate}>Update</button>
              </div></div>
          </div>   </>
          : null}

        {Suggestedarr.length > 0 ?
          <div className='showcaserelated'>
            <div className='centerstore'>
              <h2 className='h2sugested' style={{ textAlign: "center" }}><strong className='strong'>Suggested Games</strong></h2>
            </div>
            <div className='centerstore'>
            <ProgressiveImage src={witch} >{(src, loading) => (<img className={`witch${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
        
            <img class="detailsoverlay" src={detailsoverlay} ></img>
            <div class="sug-container">
              {Suggestedarr.map((x, i, z) =>
             <>
                  <div style={{ display: "none" }}>{count = count + 0.5}</div>
                  <Link to={`/games/${x.id}`}>
                  <SuggestedGames key={x.id} x={x} edit={edit} user={user} remove={remove} count={count} />
                </Link></>)}
            </div>
          </div>
          : <div className='showcaserelated'>
            <div className='centerstore'>
              <h2 className='h2sugested' style={{ textAlign: "center" }}><strong className='strong'>Suggested Games</strong></h2>
            </div>
            <div className='centerstore'>
            <ProgressiveImage src={witch} >{(src, loading) => (<img className={`witch${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
        
            <img class="detailsoverlay" src={detailsoverlay} ></img>
            <div class="sug-container">
              {Suggestedarr2.map((x, i, z) =>
                 <Link to={`/games/${x.id}`} >
                  <div style={{ display: "none" }}>{count = count + 0.5}</div>
                  <SuggestedGames key={x.id} x={x} edit={edit} user={user} remove={remove} count={count} />
                </Link>)}
            </div>
          </div>}
      </>

      } </>
  );
}


