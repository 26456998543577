import React, { useEffect, useState, useContext } from 'react'
import db from '../../db'
import games from '../../images/games.mp4'
import Cd from './Cd'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap";
import "../../css/games.css";
import "../../css/carousel.css";
import "../../css/cardsstyle.css";
import "../../css/buttonsframe.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loader from '../../images/loader.png'
import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import UserContext from '../../UserContext'
import ProgressiveImage from "react-progressive-graceful-image";

export default function Cds() {
    const [cds, setCds] = useState([])
    const { user } = useContext(UserContext)
    useEffect(() => db.Cds.listenAll(setCds), [])
    function animateFrom(elem, direction) {
        direction = direction || 1;
        var x = 0,
            y = 0;
        if (elem.classList.contains("up")) {
            elem.style.transform = "translate(" + x + "px, " + y + "px)";
            elem.style.opacity = "0";
            gsap.fromTo(elem, { x: 0, y: 20, autoAlpha: 0 }, {
                duration: 1.5,
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",


            });
        }
        else if (elem.classList.contains("up1")) {
            elem.style.transform = "translate(" + x + "px, " + y + "px)";
            elem.style.opacity = "0";
            gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",
                delay: 0.2

            });
        }
        else if (elem.classList.contains("up2")) {
            elem.style.transform = "translate(" + x + "px, " + y + "px)";
            elem.style.opacity = "0";
            gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",
                delay: 0.4

            });
        }

        else if (elem.classList.contains("up3")) {
            elem.style.transform = "translate(" + x + "px, " + y + "px)";
            elem.style.opacity = "0";
            gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",
                delay: 0.6

            });
        }
        else if (elem.classList.contains("up4")) {
            elem.style.transform = "translate(" + x + "px, " + y + "px)";
            elem.style.opacity = "0";
            gsap.fromTo(elem, { x: -40, y: 30, autoAlpha: 0 }, {
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",
                delay: 0.8

            });
        }
        else if (elem.classList.contains("upp")) {
            elem.style.transform = "translate(" + x + "px, " + y + "px)";
            elem.style.opacity = "0";
            gsap.fromTo(elem, { x: 0, y: 30, autoAlpha: 0 }, {
                duration: 2,
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",


            });
        }
    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    document.addEventListener("DOMContentLoaded", function () {
        gsap.registerPlugin(ScrollTrigger);
        gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem) },
                onEnterBack: function () { animateFrom(elem, -1) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
    });

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <>
            {loading ? (
                <div className="loader-container">
                    <div className='centerstore'>
                        <ProgressiveImage src={loader} >{(src, loading) => (<img className={`spinner${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
                    </div>
                    <p className='lotext' > Loading...</p>
                </div>
            ) : <>
                <div class="jumbotron jumbotron-fluid " >
                    <div id="vi" >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<video id="videeo" autoplay loop muted playsinline><source src=${games} type="video/mp4" />Your browser does not support the video tag.</video>`,}}
                        />
                    </div>
                </div>
                <div class="sp-container"  >
                    <h2 class="frame-5" >
                        <span style={{ textAlign: "center" }}>Playstation Games</span>
                    </h2>
                </div>
                <div className='allglid' >
                    <h2 className='h2' style={{ textAlign: "center" }}><strong>Action</strong></h2>
                    <Glider draggable={true} infinite slidesToShow={4.02}>
                        <div className='glider-contain'>
                            <div class="glider-track">
                                {
                                    cds.map((x, i) =>
                                        ((x.gener.split(",")[0] == "Action") && (x.gener.split(",")[0] !== "Adventure") && (x.gener.split(",")[1] !== " Adventure") && (x.gener.split(",")[2] !== " Adventure")) ||
                                            ((x.gener.split(",")[1] == " Action") && (x.gener.split(",")[0] !== "Adventure") && (x.gener.split(",")[1] !== " Adventure") && (x.gener.split(",")[2] !== " Adventure")) ||
                                            ((x.gener.split(",")[2] == " Action") && (x.gener.split(",")[0] !== "Adventure") && (x.gener.split(",")[1] !== " Adventure") && (x.gener.split(",")[2] !== " Adventure"))
                                            ?
                                            <Cd key={x.id} x={x} />
                                            : null
                                    )
                                }
                            </div></div>
                    </Glider><br></br><br></br>
                    {/* -------------------------------------------------------------------- */}
                    <h2 className='h2' style={{ textAlign: "center" }}><strong>Family</strong></h2>
                    <Glider draggable={true} infinite slidesToShow={4.02}>
                        <div className='glider-contain'>
                            <div class="glider-track">
                                {
                                    cds.map((x, i) =>

                                        ((x.gener.split(",")[0] == "Family")) ||
                                            ((x.gener.split(",")[1] == " Family")) ||
                                            ((x.gener.split(",")[2] == " Family"))
                                            ?
                                            <Cd key={x.id} x={x}  />
                                            : null
                                    )
                                }
                            </div></div>
                    </Glider><br></br><br></br>
                    {/* -------------------------------------------------------------- */}
                    <h2 className='h2' style={{ textAlign: "center" }}><strong>Shooter</strong></h2>
                    <Glider draggable={true} infinite slidesToShow={4.02}>
                        <div className='glider-contain'>
                            <div class="glider-track">
                                {
                                    cds.map((x, i) =>

                                        ((x.gener.split(",")[0] == "Shooter")) ||
                                            ((x.gener.split(",")[1] == " Shooter")) ||
                                            ((x.gener.split(",")[2] == " Shooter"))
                                            ?
                                            <Cd key={x.id} x={x}  />
                                            : null
                                    )
                                }
                            </div></div>
                    </Glider><br></br><br></br>
                    {/* -------------------------------------------------------------- */}
                    <h2 className='h2' style={{ textAlign: "center" }}><strong>Adventure</strong></h2>
                    <Glider draggable={true} infinite slidesToShow={4.02}>
                        <div className='glider-contain'>
                            <div class="glider-track">
                                {
                                    cds.map((x, i) =>
                                        ((x.gener.split(",")[0] == "Adventure") && (x.gener.split(",")[0] !== "Action") && (x.gener.split(",")[1] !== " Action") && (x.gener.split(",")[2] !== " Action"))
                                            ?
                                            <Cd key={x.id} x={x}  />
                                            : null
                                    )
                                }
                            </div></div>
                    </Glider><br></br><br></br>
                    {/* -------------------------------------------------------------- */}
                    <h2 className='h2' style={{ textAlign: "center" }}><strong>Racing & Driving</strong></h2>
                    <Glider draggable={true} infinite slidesToShow={4.02}>
                        <div className='glider-contain'>
                            <div class="glider-track">
                                {
                                    cds.map((x, i) =>

                                        ((x.gener.split(",")[0] == "Racing")) ||
                                            ((x.gener.split(",")[1] == " Racing")) ||
                                            ((x.gener.split(",")[2] == " Racing"))
                                            ?
                                            <Cd key={x.id} x={x}  />
                                            : null
                                    )
                                }
                            </div></div>
                    </Glider><br></br><br></br>
                </div>
                <div />
            </>}  </>
    );
}