import React, { useEffect, useState } from 'react'
import xbut from '../../images/create frame/x.png'
import editbut from '../../images/create frame/editbut.png'
import db from '../../db'
import language from '../../images/language.png'
import player from '../../images/player.png'
import year from '../../images/year.png'
import playstation from '../../images/playstation.png'
import {Link} from "react-router-dom"
export default function Gamesdetails({ x, arr, edit, remove, user, limit }) {
  const [games, setGames] = useState(null)
  useEffect(() => (async () => setGames(await db.Cds.findOne(x.cdid)))(), [x.cdid])
  const [stores, setstores] = useState([])
  useEffect(() => db.Stores.listenAll(setstores), [])

  let lang = games && games.language.split(',')
  let count = 0;
  return (
    <>
      {limit == 1 ?
        <>
          <div className=''>
            <div><span className='gamename'> {games && games.name}</span></div>
            <div className='centerstore'>
              <div class="games-container" data-aos="zoom-in" >
                <div class="gcardd" data-aos="zoom-in" >
                  <div class="g-game-card">
                    <div class="g-game-card__cover" style={{ background: `url("${games && games.smallimage}") no-repeat center center/cover` }}></div>
                  </div></div>
              </div> </div>
            <div className='gameinfo'>
              <div data-aos="zoom-in" style={{ transitionDuration: `0.5s` }} ><img class="player" src={player}></img><span class="playertext">{games && games.players}</span> </div>
              <div data-aos="zoom-in" style={{ transitionDuration: `0.8s` }}><img class="year" src={year}></img><span class="yeartext">{games && games.date.getFullYear()}</span> </div>
              <div data-aos="zoom-in" style={{ transitionDuration: `1s` }}><img class="playstation" src={playstation}></img><span class="playstationtext">{games && games.platform}</span> </div>
              <div data-aos="zoom-in" style={{ transitionDuration: `1.2s` }}>
                <img class="language" src={language}></img>
                <span class="languagetext">
                  {`${games && lang[0] !== undefined ? games && lang[0] + "," : ""}
                    ${games && lang[1] !== undefined ? games && lang[1] + "," : ""}
                    ${games && lang[2] !== undefined ? games && lang[2] : ""}`}
                </span></div>
            </div>
          </div>

          <div className='divgener' >
            <h2 className='h2stores' style={{ textAlign: "center" }}><strong className='strong'>Prices in different stores</strong></h2>
            <div class="st-container">
              {arr.map((s) => stores.map((j) => s.storeid == j.id ?
                <>
                  <div style={{ display: "none" }}>{count = count + 0.5}</div>
                  <div class="stcard" data-aos="zoom-in-up" style={{ transitionDuration: `${count}s` }}>
                    <div class="b-game-cardst">
                      {user && user.role == "Admin" ?
                        <div className='butst' >
                          <img class="xbutst" src={xbut} ></img>
                          <button class="xbuttst" variant="light" onClick={() => remove(s.id)} >X</button>
                          <img class="editbutst" src={editbut} ></img>
                          <button class="editbuttst" variant="light" onClick={() => edit(s.id)}>Edit</button>
                        </div> : null}
                      <div class="b-game-cardst__cover">
                        <Link to={`/storedetails/${j.id}`}>
                          <img class="iconn" src={`${j.image}`}></img>
                        </Link>

                        <p class="titlestore">{j.name}</p>
                        <p class="pricest">Price: <span style={{ color: "rgb(255, 230, 0)" }}>{s.price}</span>QR</p>
                      </div>
                    </div>
                  </div>

                </>
                : null))}
            </div>
            <br></br>
          </div>

        </> : null

      }







    </>
  )
}











