import React, { useEffect, useState, useContext } from 'react'
import db from '../../db'
import ps4style from '../../images/ps4style.png'
import ps4circle from '../../images/ps4circle.png'
import a3 from '../../images/a3.png'
import a2 from '../../images/a2.png'
import but from '../../images/but.png'
import loader from '../../images/loader.png'
import "../../css/ps4.css";
import PS4details from './PS4details'
import UserContext from '../../UserContext'
import createFrame from '../../images/create frame/createFrame.png'
import createbut from '../../images/create frame/createbut.png'
import updatebut from '../../images/create frame/updatebut.png'
import ProgressiveImage from "react-progressive-graceful-image";
export default function Ps4() {
  const [cds, setCds] = useState([])
  const [id, setId] = useState(0)
  const [name, setName] = useState("")
  const [smallimage, setSmallImage] = useState("")
  const [largeimage, setLargeImage] = useState("")
  const [players, setPlayer] = useState("")
  const [date, setDate] = useState(new Date())
  const [platform, setPlatform] = useState("")
  const [language, setLang] = useState("")
  const [gener, setGener] = useState("")
  const { user } = useContext(UserContext)
  const [searchedItem, setSearchedItems] = useState([])
  const [search, setSearch] = useState("")
  useEffect(() => db.Cds.listenAll(setCds), [])

  const filtersearch = (value) => {
    setSearch(value)
    setSearchedItems(cds.filter(item => item.name.toLowerCase().includes(value.toLowerCase())))
  }

  const create = async () => {
    await db.Cds.create({ name, smallimage, largeimage, players, date, platform, language, gener })
    setId(0)
    setName("")
    setSmallImage("")
    setLargeImage("")
    setPlayer("")
    setDate(new Date())
    setPlatform("")
    setLang("")
    setGener("")
  }


  const remove = async id => await db.Cds.remove(id)

  const edit = async id => {
    const x = await db.Cds.findOne(id)
    setId(x.id)
    setName(x.name)
    setSmallImage(x.smallimage)
    setLargeImage(x.largeimage)
    setPlayer(x.players)
    setDate(x.date)
    setPlatform(x.platform)
    setLang(x.language)
    setGener(x.gener)
  }

  const update = async () => {
    await db.Cds.update({ id, name, smallimage, largeimage, players, date, platform, language, gener })
    setId(0)
    setName("")
    setSmallImage("")
    setLargeImage("")
    setPlayer("")
    setDate(new Date())
    setPlatform("")
    setLang("")
    setGener("")

  }

  const [validCreate, setValidCreate] = useState(false)
  useEffect(() => setValidCreate(
    name !== "" &&
    smallimage !== "" &&
    largeimage !== "" &&
    players !== "" &&
    platform !== "" &&
    language !== "" &&
    gener !== ""
  ), [name, smallimage, , largeimage, players, date, platform, language, gener])

  const [validUpdate, setValidUpdate] = useState(false)
  useEffect(() => (async () => setValidUpdate(
    name !== "" &&
    smallimage !== "" &&
    largeimage !== "" &&
    players !== "" &&
    platform !== "" &&
    language !== "" &&
    gener !== "" &&
    await db.Cds.findOne(id) !== undefined
  ))(), [id, name, smallimage, , largeimage, players, date, platform, language, gener])


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  let counter = 0;

  return (

    <>
      {loading ? (
        <div className="loader-container">
          <div className='centerstore'>                       
           <ProgressiveImage src={loader} >{(src, loading) => (<img className={`spinner${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <p className='lotext' > Loading...</p>
        </div>
      ) : (
        <body className='allpscs'>
          <div className='centerstore'>
            <ProgressiveImage src={a3} >{(src, loading) => (<img className={`a3${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'>
            <ProgressiveImage src={ps4style} >{(src, loading) => (<img className={`ps4style${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'>
            <ProgressiveImage src={ps4circle} >{(src, loading) => (<img className={`ps4circle${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'>
            <ProgressiveImage src={a2} >{(src, loading) => (<img className={`a2${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'>
            <ProgressiveImage src={but} >{(src, loading) => (<img className={`a3but${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <div className='centerstore'><input class="a3text" size="sm" type="text" onChange={event => filtersearch(event.target.value)} value={search} placeholder="Search" /></div>


          <div className='margin'>
            {user && user.role == "Admin" ?
              <>  <div className='cdspace'>
                <div className='allframecd'>
                  <img class="nameframe" src={createFrame} ></img>
                  <input class="nameframetext" type="text" onChange={event => setName(event.target.value)} placeholder="Name" value={name} />
                  <img class="phoneframe" src={createFrame} ></img>
                  <input class="phoneframetext" type="text" onChange={event => setSmallImage(event.target.value)} placeholder="Small Image" value={smallimage} />
                  <img class="locframe" src={createFrame} ></img>
                  <input class="locframetext" type="text" onChange={event => setLargeImage(event.target.value)} placeholder="Large Image" value={largeimage} />
                  <img class="imageframe" src={createFrame} ></img>
                  <input class="imageframetext" type="text" onChange={event => setPlayer(event.target.value)} placeholder="Players" value={players} />
                  <div className='crupbut2'>
                    <img class="createbut" src={createbut} ></img>
                    <button className='createbuttext' variant="light" onClick={create} disabled={!validCreate}>Create</button>
                    <img class="updatebut" src={updatebut} ></img>
                    <button className='updatebuttext' variant="light" onClick={update} disabled={!validUpdate}>Update</button>
                  </div></div>
                <div className='allframe2cd'>
                  <img class="nameframe" src={createFrame} ></img>
                  <input class="nameframetext" type="date" placeholder="date" value={date.toLocaleDateString('en-CA')} onChange={event => setDate(new Date(event.target.value))} />
                  <img class="phoneframe" src={createFrame} ></img>
                  <input class="phoneframetext" type="text" onChange={event => setPlatform(event.target.value)} placeholder="Platform" value={platform} />
                  <img class="locframe" src={createFrame} ></img>
                  <input class="locframetext" type="text" onChange={event => setLang(event.target.value)} placeholder="Language" value={language} />
                  <img class="imageframe" src={createFrame} ></img>
                  <input class="imageframetext" type="text" onChange={event => setGener(event.target.value)} placeholder="Gener" value={gener} />
                </div>
              </div>   </>
              : null}
            {
              search
                ?
                <div class="ps4-container">
                  {searchedItem.map((x, i) => x.platform == "PS4" ?
                    <>
                      <div style={{ display: "none" }}>{counter++}</div>
                      <div style={{ display: "none" }}>{counter = counter - 0.8}</div>
                      <PS4details key={x.id} x={x} counter={counter} edit={edit} remove={remove} user={user} />
                    </> : null)}
                </div>


                :
                <div class="ps4-container">
                  {cds.map((x, i) => x.platform == "PS4" ?
                    <>
                      <div style={{ display: "none" }}>{counter++}</div>
                      <div style={{ display: "none" }}>{counter = counter - 0.8}</div>
                      <PS4details key={x.id} x={x} counter={counter} edit={edit} remove={remove} user={user} />
                    </> : null)}
                </div>
            }
          </div>

        </body>
      )}
    </>
  );
}