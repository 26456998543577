import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import loginn from '../../images/login.png';
import but from '../../images/but.png'
import reg from '../../images/reg.png'
import regright from '../../images/regright.png'
import $ from 'jquery';
import bubbles from '../../images/bubbles.png'
import bubbles2 from '../../images/bubbles2.png'
import "../../css/register.css";
import firebase from '../../firebase'
import ProgressiveImage from "react-progressive-graceful-image";

export default function Register() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const history = useHistory()
    let errorCode =""
    
    const register = async () => {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
        .catch(function (error) {
            errorCode= error.code;
            if ((errorCode === 'auth/user-not-found')||(password !== password2)) {
                $(".errorpass").show();
            }
        });
        if(password === password2){
        if ((errorCode !== 'auth/user-not-found')) {
            history.push("/")
        }
    }
    }

    const [validregister, setValidRegister] = useState(false)
    useEffect(() => setValidRegister(
        email !== "" &&
        password !== "" &&
        password2 !== ""
    ), [email, password, password2])


    return (
        <>
            <div className='alllogin'>
                <div className='centerstore'><img class="stlogin" src={loginn}></img></div>
                <div className='centerstore'><img class="bubbles" src={bubbles}></img></div>
                <div className='centerstore'><img class="bubbles2" src={bubbles2}></img></div>
                <div className='centerstore'>
                    <ProgressiveImage src={reg} >{(src, loading) => (<img className={`reg${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
                </div>
                <div className='centerstore'>
                    <ProgressiveImage src={regright} >{(src, loading) => (<img className={`regright${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
                </div>
                <div className='centerstore'><div className='error' style={{ display: "none" }}> *Username or password is not correct*</div></div>
                <div className='centerstore'><div className='errorpass' style={{ display: "none" }}> *Passwords don't match or email already exists*</div></div>
                <div className='centerstore'><img class="but" src={but}></img></div>
                <div className='centerstore'><h1 className='login'>Register</h1></div>
                <div className='centerstore'>
                    <div class="logininput" >
                        <input class="inputtext" size="sm" type="text" placeholder="Email" onChange={event => setEmail(event.target.value)} value={email} />
                        <input class="inputtext" size="sm" type="password" placeholder="Password" onChange={event => setPassword(event.target.value)} value={password} />
                        <input class="inputtext" size="sm" type="password" placeholder="Password2" onChange={event => setPassword2(event.target.value)} value={password2} />
                    </div> </div>
                <div className='centerstore'>
                    <button class="butreg" variant="light" onClick={register} disabled={!validregister}><p class="buttype">Register</p></button >
                </div>
                <div className='space'></div>
            </div>
        </>
    )

}