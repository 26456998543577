import React, { useEffect, useState, useContext } from 'react'
import db from '../../db'
import Store from './Store'
import "../../css/stores.css";
import fire from '../../images/fire.png'
import decor from '../../images/decor.png'
import loader from '../../images/loader.png'
import UserContext from '../../UserContext'
import createFrame from '../../images/create frame/createFrame.png'
import createbut from '../../images/create frame/createbut.png'
import updatebut from '../../images/create frame/updatebut.png'
import ProgressiveImage from "react-progressive-graceful-image";

export default function Stores() {
  const [Stores, setStores] = useState([])
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [phonenum, setPhoneNum] = useState("")
  const [location, setLoc] = useState("")
  const [image, setImage] = useState("")
  const { user } = useContext(UserContext)
  useEffect(() => db.Stores.listenAll(setStores), [])

  const create = async () => {
    await db.Stores.create({ id, name, phonenum, location, image })
    console.log(db.Stores)
    setId("")
    setName("")
    setPhoneNum(0)
    setImage("")
    setLoc("")
  }

  const remove = async id => await db.Stores.remove(id)

  const edit = async id => {
    const store = await db.Stores.findOne(id)
    console.log(store)
    setId(store.id)
    setName(store.name)
    setPhoneNum(store.phonenum)
    setImage(store.image)
    setLoc(store.location)
  }

  // update is step 2
  const update = async () => {
    await db.Stores.update({ id, name, phonenum, location, image })
    setId("")
    setName("")
    setPhoneNum(0)
    setImage("")
    setLoc("")
  }

  const [validCreate, setValidCreate] = useState(false)
  useEffect(() => setValidCreate(
    name !== "" &&
    phonenum > 0 &&
    location !== "" &&
    image !== ""
  ), [id, name, phonenum, location, image])

  const [validUpdate, setValidUpdate] = useState(false)
  useEffect(() => (async () => setValidUpdate(
    name !== "" &&
    phonenum > 0 &&
    location !== "" &&
    image !== "" &&
    await db.Stores.findOne(id) !== undefined
  ))(), [id, name, phonenum, location, image])


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className='centerstore'>
            <ProgressiveImage src={loader} >{(src, loading) => (<img className={`spinner${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
          </div>
          <p className='lotext' > Loading...</p>
        </div>
      ) :
        <div>
          <div className='jumbotronstore'>
            <div id='vistore'></div>
          </div>
          <div class="sp-container">
            <h2 class="frame-5">
              <span style={{ textAlign: "center", marginTop: "70px" }}>PLAYSTATION STORES</span>
            </h2>
          </div>
          <img class="fire" src={fire}></img>
          <img class="decor" src={decor}></img>
          {user && user.role == "Admin" ?
            <div className='allframe'>
              <img class="nameframe" src={createFrame} ></img>
              <input class="nameframetext" type="text" onChange={event => setName(event.target.value)} placeholder="Name" value={name} />
              <img class="phoneframe" src={createFrame} ></img>
              <input class="phoneframetext" type="text" onChange={event => setPhoneNum(1 * event.target.value)} placeholder="Phone" value={phonenum} />
              <img class="locframe" src={createFrame} ></img>
              <input class="locframetext" type="text" onChange={event => setLoc(event.target.value)} placeholder="Location" value={location} />
              <img class="imageframe" src={createFrame} ></img>
              <input class="imageframetext" type="text" onChange={event => setImage(event.target.value)} placeholder="Image" value={image} />
              <div className='crupbut'>
                <img class="createbut" src={createbut} ></img>
                <button className='createbuttext' variant="light" onClick={create} disabled={!validCreate}>Create</button>
                <img class="updatebut" src={updatebut} ></img>
                <button className='updatebuttext' variant="light" onClick={update} disabled={!validUpdate}>Update</button>
              </div></div> : null}
          <section class="section__main">
            {
              Stores.map(x =>
                <Store key={x.id} x={x} edit={edit} remove={remove} user={user} />
              )
            }
          </section>
        </div >
      }
    </>
  );
}