import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import loginn from '../../images/login.png';
import but from '../../images/but.png'
import mario from '../../images/mario.png'
import mario2 from '../../images/mario2.png'
import $ from 'jquery';
import bubbles from '../../images/bubbles.png'
import bubbles2 from '../../images/bubbles2.png'
import UserContext from '../../UserContext'
import ProgressiveImage from "react-progressive-graceful-image";
import "../../css/register.css";
import firebase from '../../firebase'

export default function Login() {
    const { user } = useContext(UserContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()
    let errorCode =""
    const login = async () => {
   await firebase.auth().signInWithEmailAndPassword(email, password)
            .catch(function (error) {
                // Handle Errors here.
                errorCode= error.code;
                if (errorCode === 'auth/user-not-found') {
                    $(".error").show();
                }
            });
            if (errorCode !== 'auth/user-not-found') {
                history.push("/")
            }
    }
    const [validlogin, setValidLogin] = useState(false)
    useEffect(() => setValidLogin(
        email !== "" &&
        password !== ""
    ), [email, password])


    return (
        <>
            <div className='alllogin'>
                <div className='centerstore'><img class="stlogin" src={loginn}></img></div>
                <div className='centerstore'><img class="bubbles" src={bubbles}></img></div>
                <div className='centerstore'><img class="bubbles2" src={bubbles2}></img></div>
                <div className='centerstore'>
                    <ProgressiveImage src={mario} >{(src, loading) => (<img className={`reg${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
                </div>
                <div className='centerstore'>
                    <ProgressiveImage src={mario2} >{(src, loading) => (<img className={`regright${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
                </div>
                <div className='centerstore'><div className='error' style={{ display: "none" }}> *Username or password is not correct*</div></div>
                <div className='centerstore'><div className='errorpass' style={{ display: "none" }}> *Passwords don't match or already exist*</div></div>
                <div className='centerstore'><img class="but" src={but}></img></div>
                <div className='centerstore'><h1 className='login'>Login</h1></div>
                <div className='centerstore'>
                    <div class="logininput" >
                        <input class="inputtext" size="sm" type="text" placeholder="Email" onChange={event => setEmail(event.target.value)} value={email} />
                        <input class="inputtext" size="sm" type="password" placeholder="Password" onChange={event => setPassword(event.target.value)} value={password} />
                    </div> </div>
                <div className='centerstore'>
                    <button class="butreg" variant="light" onClick={login} disabled={!validlogin}><p class="buttype">Login</p></button >
                </div>
                <div className='space'></div>
            </div>
        </>
    )

}