import React from 'react'
import {Link} from "react-router-dom"
import ProgressiveImage from "react-progressive-graceful-image";

export default function Cd({ x }) {
  return (
    <>
      <ProgressiveImage src={x.smallimage} >{(src, loading) => (<img className={`cardo${loading ? " loading" : " loaded"}`} src={src} />)}</ProgressiveImage>
      <Link className='overlay' to={`/games/${x.id}`}></Link>
    </>
  )
}