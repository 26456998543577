import firebase from './firebase'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const db = firebase.firestore()
class DB {

    constructor(collection) {
        this.collection = collection
    }
    reformat(doc) {
        return { id: doc.id, ...doc.data() }
    }

    findByField = async (field, value) => {
        const data = await db.collection(this.collection).where(field, '==', value).get()
        console.log(data)

        return data.docs.map(this.reformat)
    }

    create = async item => {
        const { id, ...rest } = item
        console.log(item)
        await db.collection(this.collection).add(rest)
    }

        remove = async id => {
        await db.collection(this.collection).doc(id).delete()
    }

    update = async item => {
        const { id, ...rest } = item
        await db.collection(this.collection).doc(id).set(rest)
    }

    listenOne = (set, id) =>
        db.collection(this.collection).doc(id).onSnapshot(snap => set(this.reformat(snap)))


    listenAll = set =>
        db.collection(this.collection).onSnapshot(snap => set(snap.docs.map(this.reformat)))


    findAll = async () => {
        const data = await db.collection(this.collection).get()
        return data.docs.map(this.reformat)

    }
    findOne = async id => {
        const doc = await db.collection(this.collection).doc(id).get()
        return doc.exists ? this.reformat(doc) : undefined
    }
}

class Users extends DB {
    constructor() {
        super("users")
    }
}
class Stores extends DB {

    constructor() {
        super("stores")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: 1 * item.id }
        return item
    }

}
class Cds extends DB {

    constructor() {
        super('cds')
    }

    reformat(doc) {
        return { ...super.reformat(doc), date: doc.data().date.toDate() }
    }

    async findByNameContainingIgnoreCase(name) {
        console.log(name)
        return this.reformatAll(await this.find(`search/findByNameContainingIgnoreCase?name=${name}`))
    }
    async setPlatform() {
        return [...new Set((await this.findAll()).map(x => x.platform))]
    }

    findByName = name =>
        this.findByField('name', name)

}
class Cdstores extends DB {

    constructor() {
        super("cdstores")
    }

    reformatOne(item) {
        item = super.reformatOne(item)
        item = { ...item, id: 1 * item.id }
        return item
    }

    async findByCdid(id) {
        return this.reformatAll(await this.find(`search/findByCdid?id=${id}`))
    }
    async findByStoreid(id) {
        return this.reformatAll(await this.find(`search/findByStoreid?id=${id}`))
    }
    async findDistinctByStoreid() {
        return [...new Set((await this.findAll()).map(x => x.storeid))]
    }
    findcdid = async (cdid) => {
        const data = await db.collection(this.collection).where("cdid", "==", cdid).get()
        return data.docs.map(this.reformat)
    }
    findstoreid = async (storeid) => {
        const data = await db.collection(this.collection).where("storeid", "==", storeid).get()
        return data.docs.map(this.reformat)
    }
}

export default {
    Users: new Users(),
    Stores: new Stores(),
    Cds: new Cds(),
    Cdstores: new Cdstores(),

}
