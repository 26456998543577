import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC2wiWLNodZWOqlpKiGruumyQh_y9WIdsw",
  authDomain: "playstationboo-d75cd.firebaseapp.com",
  projectId: "playstationboo-d75cd",
  storageBucket: "playstationboo-d75cd.appspot.com",
  messagingSenderId: "687736372762",
  appId: "1:687736372762:web:ff780f8e4d2c6c121cdba3",
  measurementId: "G-D1V920BTVY"
};

firebase.initializeApp(firebaseConfig)
export default firebase