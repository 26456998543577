import React from 'react'
export default function SuggestedGames({ x ,count}) {
  return (
    <>
    
       <div data-aos="zoom-in" style={{transitionDuration:`${count}s`}} >       
        <div class="cardd">
          <div class="b-game-card">
            <div class="b-game-card__cover" style={{ background: `url("${x.smallimage}") no-repeat center center/cover` }}></div>
          </div></div>
      </div>
    </>
  )
}